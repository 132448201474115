import { Field, Form, FormikHelpers, FormikProps } from "formik";
import { graphql, navigate } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { useState } from "react";
import * as Yup from "yup";

import Button from "../../../atoms/button/Button";
import PageStepAnimation from "../../../atoms/page-step-animation/PageStepAnimation";
import withFunnelWrapper from "../../../features/quotes-funnel/ui/organisms/with-funnel-wrapper/with-funnel-wrapper";
import { DealEventProperties } from "../../../organisms/deal-event-properties/domain/deal-event-properties";
import { useDealEventProperties } from "../../../organisms/deal-event-properties/use-cases/deal-event-properties-use-cases";
import PageStepForm from "../../../organisms/page-step-form/PageStepForm";
import { Result } from "../../../organisms/result/domain/result";
import { useResult } from "../../../organisms/result/use-cases/result-use-cases";
import { PageId } from "../../../settings/pages";
import { PetSex } from "../../../settings/pet";
import { Events, trackChoice } from "../../../utils/analytics";
import useTrackPageViewed from "../../../utils/hooks/useTrackPageViewed";
import { shouldShowHasTattooAnswer } from "../../../utils/locale-configuration-utils";
import * as styles from "../../HasChip.module.scss";

const petChipSVG: string = require("../../../images/pages/results/has-chip/petChip.svg").default;

interface HasChipFormValues {
  pet_has_chip: string;
}

const Page = (): JSX.Element => {
  const { t } = useTranslation();
  const result = useResult() as Result;
  const dealEventProperties: DealEventProperties | null = useDealEventProperties();
  const [petHasChip, setPetHasChip] = useState(!!result?.pet_chip);

  useTrackPageViewed(Events.HAS_CHIP_BROWSER);

  const handleClick = (e) => {
    const answer = e.target.value;
    const properties = {
      ...dealEventProperties,
      question: "has-chip",
      answer,
    };

    trackChoice(e, properties);
    setPetHasChip(true);
  };

  const hasChipFormData = {
    initialValues: { pet_has_chip: result.pet_chip ? "yes" : null },
    validationSchema: Yup.object({
      pet_has_chip: Yup.string().required(t("common.validation.required")),
    }),
    handleSubmit: (values, { setSubmitting }: FormikHelpers<HasChipFormValues>) => {
      setSubmitting(true);
      void navigate(
        `/results/${result?.uuid}/${values.pet_has_chip === "yes" ? "pet_parent" : "no_chip"}/`
      );
    },
    children: (props: FormikProps<HasChipFormValues>) => {
      const { isSubmitting } = props;

      return (
        <Form className={styles.hasChipForm}>
          <Field type="radio" name="pet_has_chip" value="yes" id="yes" onClick={handleClick} />
          <label htmlFor="yes">{t("has_chip.answers.yes")}</label>

          <Field
            type="radio"
            name="pet_has_chip"
            value="no_too_young"
            id="no_too_young"
            onClick={handleClick}
          />
          <label htmlFor="no_too_young">{t("has_chip.answers.no_too_young")}</label>

          {shouldShowHasTattooAnswer(result.country) && (
            <>
              <Field
                type="radio"
                name="pet_has_chip"
                value="has_tattoo"
                id="has_tattoo"
                onClick={handleClick}
              />
              <label htmlFor="has_tattoo">
                {t(
                  `has_chip.answers.has_tattoo.${
                    result.pet_sex === PetSex.male ? "male" : "female"
                  }`
                )}
              </label>
            </>
          )}

          <Field
            type="radio"
            name="pet_has_chip"
            value="did_not_know"
            id="did_not_know"
            onClick={handleClick}
          />
          <label htmlFor="did_not_know">{t("has_chip.answers.did_not_know")}</label>

          <Button type="submit" disabled={isSubmitting || !petHasChip} isLoading={isSubmitting}>
            {t("chip.cta")}
          </Button>
        </Form>
      );
    },
  };

  return (
    <>
      {result && (
        <PageStepAnimation>
          <PageStepForm
            title={t("pg_has_chip.title", { petName: result?.pet_name })}
            subtitle={t("pg_has_chip.subtitle")}
            image={petChipSVG}
            formData={hasChipFormData}
          />
        </PageStepAnimation>
      )}
    </>
  );
};

export default withFunnelWrapper(Page, "pg-has-chip", PageId.hasChip);

export const query = graphql`
  query ChipQuestionQuery {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
